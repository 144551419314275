<template>
    <LiefengContent>
        <template v-slot:title>{{ `${$route.query.name || ""}机构首次发布内容情况统计` }}</template>
        <template v-slot:toolsbarRight>
            <Form class="search" :label-width="100" :inline="true">
                <FormItem label="时间段：">
                    <DatePicker separator=" 至 " :value="date" format="yyyy-MM-dd" :clearable="false" type="daterange" placement="bottom-end" placeholder="选择时间段" style="width: 220px" @on-change="changeDate" transfer></DatePicker>
                </FormItem>
                <!-- <FormItem label="是否已发布">
                    <Select v-model="publish" transfer style="width: 200px;margin:0 20px">
                        <Option :value="0">全部</Option>
                        <Option :value="1">已发布内容</Option>
                    </Select>
                </FormItem> -->
                <Button
                    type="info"
                    icon="ios-search"
                    @click="
                        () => {
                            hadlePageSize()
                        }
                    "
                >
                    查询
                </Button>
                <Button
                    type="default"
                    icon="md-download"
                    style="margin: 0 5px;"
                    @click="
                        () => {
                            downloadData()
                        }
                    "
                >
                    导出报表
                </Button>
                <RadioGroup v-model="monthrange" type="button" button-style="solid" style="display: inline-flex;" @on-change="onChangeDateByMonth">
                    <Radio :label="-1">近一个月</Radio>
                    <Radio :label="-3">近三个月</Radio>
                    <Radio :label="-6">近半年</Radio>
                </RadioGroup>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :tableData="tableData" :talbeColumns="talbeColumns" :loading="loading" :fixTable="true" :hidePage="true" :showIndex="true"></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/userscount')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            seleteType: "按区",
            date: (() => {
                const end = new Date()
                const start = new Date()
                start.setFullYear(start.getFullYear() - 1)
                return [this.$core.formatDate(new Date(start), "yyyy-MM-dd"), this.$core.formatDate(new Date(end), "yyyy-MM-dd")]
            })(),
            talbeColumns: [
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "账号开通日期",
                    key: "orgOpenDate",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "首次发布内容日期",
                    key: "firstPublishDate",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "累计发布信息数",
                    key: "pubInfoNum",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "最近更新日期",
                    key: "lastUpdatePublishDate",
                    minWidth: 100,
                    align: "center",
                },
            ],
            tableData: [],
            loading: false,
            pageSize: 100,
            total: 0,
            curPage: 1,
            onlineType: "",
            codeOptionsTwo: [],
            publish: 0,
            monthrange: null,
        }
    },
    methods: {
        changeDate(value) {
            this.date = [this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd"), this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd")]
            this.monthrange = null
        },
        downloadData() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["机构名称", "账号开通日期", "首次发布内容日期", "累计发布信息数", "最近更新日期"]
                    let filterVal = ["orgName", "orgOpenDate", "firstPublishDate", "pubInfoNum", "lastUpdatePublishDate"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, `${this.$route.query.name || ""}机构首次发布内容情况统计`)
                },
            })
        },
        hadlePageSize() {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/manages/statFirstOrgPubInfo", {
                oemCode: parent.vue.oemInfo.oemCode,
                dimensionId: this.$core.getUrlParam("dimensionId") || "",
                startDate: this.date && this.date.length ? this.date[0] : "",
                endDate: this.date && this.date.length ? this.date[1] : "",
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                    } else {
                        this.tableData = []
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },
        getCode() {
            this.$get(
                "/orgzz/pc/dimension/selectAuthorizationDimension",
                {
                    appCode: "",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.codeOptionsTwo = [{ id: "", code: "", name: "全部" }]
                if (res.code == 200) {
                    res.dataList.map(item => {
                        if (item) {
                            this.codeOptionsTwo.push(item)
                        }
                    })
                }
                // this.codeOptions = res.dataList
            })
        },

        onChangeDateByMonth(month) {
            const start = new Date()
            start.setMonth(start.getMonth() + month)
            this.date = [this.$core.formatDate(start, "yyyy-MM-dd"), this.$core.formatDate(new Date(), "yyyy-MM-dd")]
            this.hadlePageSize()
        },
    },
    mounted() {
        // this.getCode()
        this.hadlePageSize()
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>

<style scoped lang="less">
/deep/ .ivu-radio-group {
    display: flex;
    margin-right: 20px;
}
</style>
